import React from 'react'
import { InferGetServerSidePropsType } from 'next'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import Layout from '@scentregroup/shared/hub-components/layout'
import {
  fetchNationalStaticData,
  NationalStaticData,
} from '@scentregroup/shared/helpers/fetch-header-static-data'

import { CoreContainer } from '@hub/core-container'
import { Template } from '@scentregroup/shared/components/page-templates/template'
import { upperFirst } from 'lodash'
import { AnalyticsPropertiesContext } from '@scentregroup/shared/context'
import { getFlexibleContentPage } from '../../../lib/page-templates/fetching/get-flexible-page'
import { propsOrNotFound } from '../../../lib/props-or-not-found'
import { makeApolloClient } from '../../../lib/apolloClient'
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from '../../../types/next'
import { injectGiftCardActivation } from '../../../lib/inject-gift-card-activation'

export async function getServerSideProps(
  ctx: GetServerSidePropsContext<{
    path: string[]
  }>
): Promise<GetServerSidePropsResult<MembershipFlexiblePageProps>> {
  const subpath = ctx.params?.path || []

  // limit the path to 2 segments
  if (subpath.length > 2) {
    return {
      notFound: true,
    }
  }

  const path = ['membership', ...subpath]

  setCacheHeaders(ctx.res)
  const client = makeApolloClient()
  const country = localeToCountry(ctx.locale)

  const [nationalHeaderData, template] = await Promise.all([
    fetchNationalStaticData(client, { country }),
    getFlexibleContentPage(client, country, path.join('/')),
  ])

  if (!template) {
    return {
      notFound: true,
    }
  }

  return propsOrNotFound({
    props: {
      country,
      nationalHeaderData,
      template: injectGiftCardActivation(template, ctx),
      path,
    },
  })
}

interface MembershipFlexiblePageProps {
  country: ReturnType<typeof localeToCountry>
  nationalHeaderData: NationalStaticData
  template: NonNullable<Awaited<ReturnType<typeof getFlexibleContentPage>>>
  path: string[]
}

const MembershipFlexiblePage: React.FC<
  React.PropsWithChildren<
    InferGetServerSidePropsType<typeof getServerSideProps>
  >
> = props => {
  const { country, nationalHeaderData, template, path } = props

  const metaData = {
    title: template.title,
  }

  return (
    <Layout
      country={country}
      topStrip={null}
      centre={undefined}
      metaData={metaData}
      header={{
        variant: 'national',
        data: nationalHeaderData,
      }}
      showCVPStrip={false}
      showRobotsMetaTag={false}
      breadcrumbs={[
        {
          label: 'Home',
          url: '/',
        },
        ...path.map((segment, index, path) => ({
          label:
            index === path.length - 1 ? template?.title : upperFirst(segment),
          url: `/${path.slice(0, index + 1).join('/')}`,
        })),
      ]}
    >
      <CoreContainer
        sx={{
          '& .module-rich-text': {
            maxWidth: 'size-max-line-length',
            margin: '0 auto',
          },
          // exclude the first rich text module from the max-width
          '& > div > div:first-child .module-rich-text': {
            maxWidth: 'unset',
          },
        }}
      >
        {template && (
          <AnalyticsPropertiesContext.Provider
            value={{ context: 'westfield membership' }}
          >
            <Template template={template} activateH1Title={false} />
          </AnalyticsPropertiesContext.Provider>
        )}
      </CoreContainer>
    </Layout>
  )
}

export default MembershipFlexiblePage
